import React from "react"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import MyImg from "../components/MyImg"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import { cardTitle } from "assets/jss/material-kit-react.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

const styles = {
  cardTitle,
}

const IndexPage = props => {
  const { classes } = props
  return (
    <>
      <SEO title="Acerca de nosotros" />
      <Layout>
        <section className="bg-light py-5 rounded">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <Card>
                  <CardHeader color="primary">
                    <h4 className={`${classes.cardTitle} mb-0 text-white`}>
                      Acerca de nosotros
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <p>
                      Somos un catering con un servicio de alimentación variado
                      con un excelente sabor que se adapta a tu presupuesto,
                      permítenos hacer de tu evento un momento especial para ti
                      y para todos tus invitados. Nuestra principal meta es
                      brindar un servicio de calidad por ello los menús están
                      preparados con los ingredientes de mejor calidad para tu
                      satisfacción, donde puedes adaptar tu plato de nuestra
                      variedad de menús disponibles para tu gusto y necesidad.
                    </p>
                    <p>
                      En Catering Glumière te ofrecemos una comida especial para
                      tu evento especial.
                    </p>
                  </CardBody>
                </Card>
              </div>
              <div className="col-md-6 col-lg-4 m-auto">
                <MyImg
                  name="about-main.jpg"
                  className="rounded-circle img-fluid shadow"
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default withStyles(styles)(IndexPage)
